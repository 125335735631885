import React from 'react'

function Welcome2() {
    return (
        <section>
            <div className="block color-scheme-1">
                
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-sub-title center">
                                <article className="section-title-body white">
                                    <h1 className="head-title">
                                        <span>Rico Cunningham |</span> Professional Biography </h1>
                                    <p className="head-text">
                                    Rico Cunningham is a seasoned Information Systems Consultant and Project Manager, renowned for his strategic acumen in integrating advanced technologies like blockchain, AI, and Web3 into traditional business frameworks. With a comprehensive background in IT and marketing, Rico specializes in elevating operational capacities and market presence through cutting-edge digital strategies. His expertise encompasses blockchain applications, smart contract development, and leveraging AI to drive business innovation and efficiency.
                                    </p>

                                    <p className='head-text'>Currently leading at JMA Holdings - Reign Unlimited LLC, Rico spearheads initiatives to meld Web3 technologies with existing infrastructures, ensuring that digital transformations are both seamless and potent. His role includes conducting high-impact client consultations, overseeing the development of strategic marketing, and implementing digital strategies that significantly enhance brand visibility and engagement.</p>

                                    <p className='head-text'>Prior to his current role, Rico spent over two decades at FedEx Services, where he excelled as an Integrated Solutions Consultant. There, he was pivotal in automating processes and delivering technical solutions tailored to optimize business operations and customer satisfaction on a global scale.</p>

                                    <p className='head-text'>Rico's educational foundation is robust, with credentials from prestigious programs including a Bachelor of Science in Business Management from Virginia State University, specialized certifications in blockchain from the University of Nicosia and IBM, and a recent diploma in Digital Marketing from Go Pro Academy. His technical proficiency spans across multiple programming languages and platforms, making him a versatile asset in any technological discourse.</p>

                                    <p className='head-text'>A recognized leader, Rico has been honored with numerous awards, including induction into the FedEx IT Hall of Fame and receiving accolades from the U.S. Department of Justice and the NAACP for his outstanding contributions to technology and community service.</p>

                                    <p className='head-text'>Join Rico in shaping the future of digital business landscapes, where his blend of strategic insight and technological expertise continues to drive revolutionary changes across industries.</p>
                                </article>
                            </div>   
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Welcome2
