import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

const portfolioAll = [
    {
      _id: '001',
      src: 'img/preview/haiti.png',
      title: 'Life for Haiti',
      category: 'NFT Tokenization',
      url: 'https://goldstandardgrp.com/projects/kosaha/'
    },
    {
      _id: '002',
      src: 'img/preview/bogle.png',
      title: 'Bogle.app NFT Media Streaming',
      category: 'Multimedia',
      url: 'https://goldstandardgrp.com/pages/magiccube.html'
    },
    {
      _id: '003',
      src: 'img/preview/dcg-1.png',
      title: 'DCG Elite Membership',
      category: 'Mastermind Trading',
      url: 'https://dcgelitenft.com'
    },
    {
      _id: '004',
      src: 'img/preview/sophia.png',
      title: 'One Humanity Institute',
      category: 'Art Tokenization',
      url: 'https://goldstandardgrp.com/onehumanity/'
    },
    {
      _id: '005',
      src: 'img/preview/decentraland.png',
      title: 'Virtual Real Estate',
      category: 'Decentraland Property',
      url: 'https://gsgnft.com'
    },
    {
      _id: '006',
      src: 'img/preview/radio.png',
      title: 'Blockchain Radio Network',
      category: 'Tokenize Live Streams',
      url: 'https://goldstandardgrp.com/pages/radionetwork.html'
    }
  ];
  

function Portfolio3() {
  return (
    <>
      <section id="portfolio">
        <div className="bg-dark"></div>
        <div className="section-block-title">
          <div className="section-title">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-sm-10">
                  <article className="section-title-body">
                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">
                      Portfolio
                    </h1>
                    <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                    <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                      Links to some of the projects in my portfolio
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block color-scheme-blur">
          <div className="container">
            <SimpleReactLightbox>
              <SRLWrapper>
                <div className="row">
                  {portfolioAll.map((portfolio) => (
                    <div className="col-lg-4 col-md-6" key={portfolio._id}>
                      <div className="portfolio-block">
                        <a href={portfolio.src}>
                          <img alt={portfolio.title} className="img-fluid" src={portfolio.src} />
                        </a>
                        <div className="portfolio-content">
                          <p>{portfolio.category}</p>
                          <h4>{portfolio.title}</h4>
                          <a href={portfolio.url} target="_blank" rel="noopener noreferrer">
                            <button className="btn-default white btn-move t2" smooth={false} duration={800}>Visit Website</button>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                                </SRLWrapper>
                            </SimpleReactLightbox> 
                        
                    </div> 
                    <div className="clearfix"></div>
                </div>
            
            
        </section>
   
        </>
    )
}

export default Portfolio3
