import React from 'react'
import {Link} from 'react-scroll'
function About2() {
    
    return (
        <>
            <section  id="about" className="single-bg ab2">
            <div className="section-block-title">
            <div className="bg-dark"></div>
                <div className="container">
                        <div className="section-sub-title center">
                            <article className="section-title-body white">
                                <h1 className="head-title">More <span>About Me</span></h1>
                            </article>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-2 d-flex align-items-center">
                                <div className="widget-block relative">
                                <div className="h100 d-none d-lg-block"></div>
                                <h4 className="widget-title">22 Years as a technology consultant</h4>
                                <p>
                                With more than two decades at the forefront of technology innovation at FedEx, I have been a pivotal witness and contributor to the transformative impact of emerging technologies on industries worldwide. My journey has evolved into the groundbreaking realms of blockchain and Web3 application development—fields I am deeply passionate about. I believe these technologies are fundamentally reshaping our interactions, transactions, and the future of digital identity and finance.
                                </p>
                                    <div className="block-feature d-lg-none d-xl-block">
                                        <i className=""></i>
                                        <a href="/">Technology Consultant</a>
                                        <p>
                                        As an accomplished IT specialist, I guide clients through the intricate landscape of technology. Armed with an in-depth understanding of the latest trends, I provide essential insights that empower businesses to stay ahead. My approach involves crafting bespoke solutions and delivering tailored educational programs that are crucial for thriving in today’s rapidly evolving digital world.
                                        </p>
                                    </div>
                                    <div className="block-feature d-lg-none d-xxl-block">
                                        <i className=""></i>
                                        <a href="/">Web3 Application Development</a>
                                        <p>
                                        Leveraging a distinguished 22-year tenure as a technology consultant, my experience at FedEx has imbued me with a profound perspective on how emerging technologies can dramatically alter global industries. This extensive background is the foundation of my expertise in navigating the adoption and integration of new tech paradigms. Today, I am deeply engaged in developing applications within the revolutionary spaces of blockchain and Web3, aiming to unlock new potentials in digital transaction and identity systems.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 d-flex align-items-end">
                                <div  className="relative  d-flex">
                                        <div className="card-block  lax lax_preset_fadeIn:50:150  lax_preset_slideY:4500:700">
                                            <div className="card-info">
                                                <h2>Critical</h2>
                                                <h4>Thinking</h4>
                                            </div>
                                        </div>
                                        <img className="img-fluid" src="img/preview/RicoStanding.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>
            <div className="block color-scheme-1">
                <div className="container">
                    <div className="welcome-article-block">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="welcome-article dark y-50">
                                    <img className="img-fluid" alt="" src="img/preview/btc.png"/>
                                    <div className="welcome-article-text">
                                        <h4>DCG Mastermind</h4>
                                        <p>Rico is an integral member of the DCG Mastermind, a collective at the forefront of integrating trading strategies with principles that enrich both life and career. This elite group champions the art of balancing market mastery with personal and professional growth, leading the way not only towards substantial professional success but also towards achieving holistic life fulfillment. This mastermind group offers invaluable networking opportunities, sharing best practices that foster both individual and collective advancement.</p>
                                        <a href="https://calendly.com/gsgconsulting/initial-consult" target="_blank" rel="noopener noreferrer">
                                        <button className="btn-default white btn-move t2" smooth={false} duration={800}>Schedule Meeting
                                        </button>
                                        </a>
                            
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="welcome-article dark">
                                    <img className="img-fluid" alt="" src="img/preview/blog19.png"/>
                                    <div className="welcome-article-text">
                                        <h4>Proven Concepts</h4>
                                        <p>In the dynamic sphere of digital assets, Rico has engineered a groundbreaking Web3 economy. He pioneered an NFT launchpad that empowers artists and creators to issue and monetize their works effectively, alongside implementing a token model that rewards community involvement and contributions. This innovative platform not only enhances the connection between creators and their audiences but also promotes a vibrant, engaging ecosystem. For businesses looking to enter or expand within the digital asset space, Rico’s expertise offers a direct path to leveraging these technologies for growth and engagement.</p>
                                        <a href="https://goldstandardgrp.com" target="_blank" rel="noopener noreferrer">
                                        <button className="btn-default white btn-move t2" smooth={false} duration={800}>Learn More
                                        </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="welcome-article dark y-50">
                                    <img className="img-fluid" alt="" src="img/preview/teach.png"/>
                                    <div className="welcome-article-text">
                                        <h4>Community Service</h4>
                                        <p>
                                        Beyond technical achievements, Rico is deeply committed to community service within the blockchain and Web3 sectors. He has initiated programs designed to illuminate these technologies' potential, aiming to cultivate a new generation of educators and innovators within the industry. His community-focused initiatives not only educate but also inspire individuals to propagate their knowledge, creating a ripple effect that enhances the industry's overall growth and sustainability. Companies and organizations partnering with Rico can expect to tap into this expansive network of educated professionals and enthusiasts, unlocking new opportunities for collaboration and development.</p>

                                        <a href="https://goldbackers.info" target="_blank" rel="noopener noreferrer">
                                        <button className="btn-default white btn-move t2" smooth={false} duration={800}>Join Me
                                        </button>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h50 d-none d-lg-block"></div>
                </div>
            </div>

        </section>
        </>
    )
}

export default About2
