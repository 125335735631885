import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-ios-analytics',
        title:'Technical Consulting',
        text:'Rico excels as a Technical Consultant, where his expertise supports clients in navigating complex technical projects. Equipped with robust communication and project management skills, he ensures that innovative solutions are delivered on schedule and exceed expectations. His ability to simplify complex technical scenarios into actionable strategies makes him a vital asset to any project team seeking efficiency and effectiveness in technology implementation.'
    },
    {
        _id:'002',
        classIcon:'ion-ios-people',
        title:'Social Influence',
        text:'As a prominent social influencer in the tech space, Rico leverages his platform and in-depth industry knowledge to guide brands and businesses. He crafts and leads impactful campaigns that capitalize on his strong communication and strategic marketing skills, resulting in significant brand engagement and market penetration. His influence extends beyond promotion, driving meaningful engagement and fostering long-term brand loyalty.'
    },
    {
        _id:'003',
        classIcon:'ion-ios-play',
        title:'AI Prompt Engeneering',
        text:'With advanced skills in AI Prompt Engineering, Rico is adept at formulating precise prompts that steer AI models towards generating specific, desired outcomes. This expertise allows him to unlock the full potential of AI technologies, enhancing productivity and innovation for his clients. His strategic use of AI Prompt Engineering positions organizations at the forefront of AI utilization, transforming data into actionable insights and competitive advantages.'
    },
    {
        _id:'004',
        classIcon:'ion-network',
        title:'Realationship Manager',
        text:'As a seasoned Relationship Manager, Rico uses his extensive network and deep industry knowledge to forge and nurture strategic partnerships. He guides individuals and organizations in aligning their business goals with market opportunities, facilitating mutual growth and success. His proactive approach in relationship management ensures that collaborations are both fruitful and sustainable.'
    },
    {
        _id:'005',
        classIcon:'ion-laptop',
        title:'Digital Asset Design',
        text:'With regards to Digital Asset Design, Rico employs his creative acumen and technical expertise to develop high-quality digital assets. His innovative solutions enhance visual communication and user engagement, optimizing the production process to meet contemporary digital standards. His work in digital asset design not only elevates brand aesthetics but also drives digital strategy forward.'
    },
    {
        _id:'006',
        classIcon:'ion-ios-book',
        title:'Mentor and Trainer',
        text:'Rico is also dedicated as a Mentor and Trainer, where he applies his vast knowledge and experience to empower individuals in their personal and professional development. Through impactful training sessions and one-on-one coaching, he helps others achieve their career objectives and enhance their skills, contributing to the cultivation of future leaders in the technology and business sectors.'
    }
    
]
function Service1() {
    return (
        <>
          <section  id="services"  className="bg-non-fixed">
            <div className="bg-inner-dark2"></div>
            <div className="bg-50-l"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12 order-md-1 justify-content-center d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Services</h1>
                                        <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                        <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                            Rico is a multi talented individual servicing a community
                                        </p>
                                        <div className="h25"></div>
                                        <div className="fucts-block wow fadeInUp animated" data-wow-duration="1.5s">
                                            <div className="fuct">
                                                <h2>5000+</h2>
                                                <h4>Subscribers</h4>
                                            </div>
                                            <div className="fuct">
                                                <h2>1500+</h2>
                                                <h4>Followers</h4>
                                            </div>
                                            <div className="fuct">
                                                <h2>100+</h2>
                                                <h4>Clients</h4>
                                            </div>
                                        </div>
                                    </article>
                                </div>  
                            </div>     
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="pad-l-45 pad-r-45 block">
                            <div className="row">
                                {services.map((service)=>(
                                    <div className="col-lg-12 col-xxl-6" key={service._id}>
                                    <div className="block-feature">
                                        <i className={service.classIcon}></i>
                                        <a href="/">{service.title}</a>
                                        <p>
                                        {service.text}
                                        </p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>  
        </>
    )
}

export default Service1
