import React from 'react';
import {FacebookShareButton,
        TelegramShareButton,
        LinkedinShareButton,
        TwitterShareButton} from "react-share";
import {FacebookIcon,
        TelegramIcon,
        LinkedinIcon,
        TwitterIcon} from "react-share";

function Footer1() {
    return (
        <>
        <footer id="footer">
            <div  className="pad-l-45 pad-r-45">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-3 col-md-6">
                            <article className="footer-info-block">
                                <div className="section-sub-title">
                                    <article className="section-title-body white">
                                        <h4 className="head-title">About</h4>
                                    </article>
                                </div>
                                <p>Social networking is the use of online platforms to connect with others. It has revolutionized the way people interact and enables individuals to build communities around shared interests.</p>
                            </article>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <article className="footer-info-block">
                                <div className="section-sub-title">
                                    <article className="section-title-body white">
                                        <h4 className="head-title">Information</h4>
                                    </article>
                                </div>
                                <ul className="list-unstyled">
                                    <li><a href="https://docs.google.com/document/d/1A_fy61mTA6VGCHHVDRsfdXizz78nCfgX/edit?usp=sharing&ouid=106315558927481274856&rtpof=true&sd=true" target='new'>Rico's Resume</a></li>
                                    <li><a href="https://calendly.com/gsgconsulting/initial-consult" target='new'>Schedule A Meeting</a></li>
                                    <li><a href="https://nftkng.com" target='new'>Crypto Training</a></li>
                                    <li><a href="https://goldbackers.info" target='new'>Discord Community</a></li>
                                    <li><a href="https://gsg-mastermind.com">DCG Mastermind</a></li>
                                </ul>
                            </article>
                            

                        </div>
                        <div className="col-xl-3 col-md-6">
                            <article className="footer-info-block">
                                <div className="section-sub-title">
                                    <article className="section-title-body white">
                                        <h4 className="head-title">Tweet</h4>
                                    </article>
                                </div>
                                <p>Can't trust Centralized Exchanges? The Eighty Twenty <a href="https://the8020.org" target='new'>#DeFi</a> Platform is now open!</p>
                                <p><a href="https://tinyurl.com/yc37ky8p" target='new'>https://tinyurl.com/yc37ky8p </a></p>
                            </article>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <article className="footer-info-block">
                                <div className="section-sub-title">
                                    <article className="section-title-body white">
                                        <h4 className="head-title">Media</h4>
                                    </article>
                                </div>
                                <ul className="list-unstyled">
                                    <li><a href="https://medium.com/@thegoldstandardgrp" target='new'>Medium</a></li>
                                    <li><a href="https://www.facebook.com/EarnMoreNow/" target='new'>Opportunity Central</a></li>
                                    <li><a href="https://www.youtube.com/channel/UCjmuOggYJuh5wwq-ORmFnkg" target='new'>YouTube</a></li>
                                   
                                </ul>
                            </article>
                        </div>
                    </div>
                    <div className="h25"></div>
                    <div className="footer-bottom">
                        <div className="row">

                            <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                                <ul className="footer-icon list-unstyled">
                                    <li>
                                        <FacebookShareButton url="https://facebook.com/djspade1" 
                                        quote= "Rico" hashtag="#rico">
                                            <FacebookIcon round={true} size={32}></FacebookIcon>
                                        </FacebookShareButton>
                                        
                                    </li>
 
                                    <li>
                                        <LinkedinShareButton url="https://www.linkedin.com/in/spade1worldwide/" >
                                            <LinkedinIcon round={true} size={32}></LinkedinIcon>
                                        </LinkedinShareButton>    
                                    </li>
                                    <li>
                                        <TwitterShareButton url="https://twitter.com/djspade1">
                                            <TwitterIcon round={true} size={32}></TwitterIcon>
                                        </TwitterShareButton>    
                                    </li>
                                    <li>
                                        <TelegramShareButton url="https://t.me/The8020contractchat">
                                            <TelegramIcon round={true} size={32}></TelegramIcon>
                                        </TelegramShareButton>    
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-xs-12 col-sm-4">
                                <div className="footer-logo">
                                <img src="img/logo-big-shop1.gif" alt="" style={{ width: '110px', height: '62px' }} />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            

        </footer>
        </>
    )
}

export default Footer1
